/* General styles for headings */

/* Homepage Section */
h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-shadow: 2px 2px #141414;
  margin-bottom: 5px;
}

#h1hp {
  padding-top: 80px;
  font-size: 6rem;
  color: #ffd400;
  animation: text-animation2 2s both;
}

.paragraph {
  color: #f8f9f0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-shadow: 2px 2px #141414;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.text {
  color: #f8f9f0;
  font-size: 1.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-shadow: 2px 2px #141414;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes text-animation2 {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes text-animation3 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#h1hp {
  animation-name: text-animation2;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.paragraph {
  opacity: 0;
  animation-name: text-animation3;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 5s;
}

.text {
  opacity: 0;
  animation-name: text-animation3;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 5s;
}

#contactbtn {
  opacity: 0;
  animation-name: text-animation2;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 4s;
}

#contactbtn:hover {
  background-color: #ffd400;
  color: #141414;
  border-color: #ffd400;
}

.corevalues::before {
  content: "Competence✅";
  color: #ffd400;
  animation: animate 10s linear 10s infinite normal;
  padding-left: 10px;
  animation-timing-function: ease-in-out;
}

@keyframes animate {
  0% {
    content: "Competence✅";
    opacity: 1;
  }
  29% {
    content: "Competence✅";
    opacity: 0;
  }

  30% {
    content: "Dedication✅";
    opacity: 0;
  }

  31% {
    content: "Dedication✅";
    opacity: 1;
  }
  60% {
    content: "Dedication✅";
    opacity: 0;
  }

  61% {
    content: "Service✅";
    opacity: 0;
  }
  62% {
    content: "Service✅";
    opacity: 1;
  }
  100% {
    content: "Service✅";
    opacity: 0;
  }
}

/* Responsive styles */
@media only screen and (max-width: 1024px) {
  #h1hp {
    padding-top: 80px;
    font-size: 300%;
    height: 100%;
  }
}

@media only screen and (max-height: 300px) {
  #h1hp {
    padding-top: 80px;
    font-size: 100%;
  }
}